#activitiesSection {
    text-align: initial;
    max-width: 1250px;
    padding: 50px 0px 0px 0px;
    margin: auto;
}

.carousel {
    max-height: 700px;
    overflow: hidden;
}

.activitiesInDesc {
    margin: 20px 0;
    min-height: 560px;
    padding: 0px 50px;
}

.activitiesInDescMainImgContainer {
    position: relative;
    height: 450px;
}

.activitiesInDescMainImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}

.ActivitieSmallImgsBtn {
    width: 35px;
    height: 35px;
    padding: 0 !important;
    margin: 10px 5px;
}

.activitiesInDesSmallImgsMainContainer {
    position: relative;
    width: 100%;
    height: 100px;
}

.activitiesInDesSmallImgsContainer {
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;

}

.carousel-item img {
    object-fit: contain;
}

.activitiesInDescText {
    padding: 30px 20px 0 20px !important;
    text-align: center;
}

@media (min-width: 768px) {

    .activitiesInDesc {
        box-shadow: 0 4px 6px -5px gray;
    }

    .carousel-item img {
        height: 100%;
    }

    .activitiesInDescText {
        padding: 150px 20px 0 20px !important;
        text-align: center;
    }
}

@media (max-width: 767px) {

    .activitiesInDesc {
        margin-top: 40px;
        text-align: center;
    }

    .carousel {
        min-height: 70%;
    }

    .carousel-item img {
        width: 100%;
    }
}