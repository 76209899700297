#companyProductsContainer{
    height:100%;
    overflow: auto;
    padding: 0;
    }

.descriptions-main-container{
    max-width: 1250px;
    margin:auto;
    text-align: left;
    position:relative
}

.descriptions-section-container{
    margin:0px 0px
}

.descriptions-header-container{
    margin:35px 0px 5px 0px;
    color:#000
}
.descriptions-intoduction-container h6{
    margin:20px 0px
}

.descriptions-bullet-container{
    margin:20px 0px
}

@media  (max-width: 1500px) {
    .descriptions-main-container{
        width:80%;
    }
  }

.hospital-icons {
    float: left;
}

.descriptionPara{
    margin-bottom: 0px;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 24px;
    text-transform: none;
    font-weight: 500;
    color: #6d7783;
}

.bulletPointsTick{
    float: left;
    margin-right: 12px;
    font-size: 18px;
    line-height: 26px;
    color: #293340;
}

.bulletPoints{
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 700;
    color: #293340;
}

h1{
    font-size: 36px;
}

@media (max-width: 978px){
h1 {
    font-size: 1.7rem !important;
}
.hospital-icons{
    width: 40px;
    top: 10px;
}
}