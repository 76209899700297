#careerSection{
    text-align: initial;
    max-width: 1250px;
    padding: 50px 0px 0px 0px;
    margin:auto;
}

.AlertRed{
    border-color: red !important;
    color:red!important
    }
    
    .AlertRed:focus, input[custom-file-input]:focus, .uneditable-input:focus {
        border-color: custom-orange;
        box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px custom-orange;
        outline: 0 none;
    }
    
    .AlertRed:focus {
        border-color: #ff4444 !important; 
        box-shadow: 0 0 0 0.2rem rgba(255,68,68,.25)!important;
    }

    #Resume{
        display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .2em;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    
.alert-career{
    position: absolute !important;

    width: calc(100% - 2em) !important;
    top: 76px !important;
    animation-name: alertMessagg;
    animation-duration: 1s;
    animation-iteration-count: 1;
    z-index:1
}

@keyframes alertMessagg {
    0% { opacity: 0; }  
    100% { opacity: 100; }
  }

#careerSection p{
    color: #6d7783;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0px;
  font-weight: 400;  
}  

#careerSection p i{
    margin-right: 10px ;
}