#bigScrollSpyNav{
    position: absolute;
    width: 75px;
    padding: 10px 0;
    top: 20%;
    right: 17px;
    /* z-index: 5; */
    border-radius: 20px 0 0 20px;
    background: #fff;
    background-color: #f8f9fa!important;
}
.navIcons{
    color: #7b7b7b;

}
.vertical-line{
    background: #7b7b7b;
    width: 2px;
    height: 15px;
    position: absolute;
    bottom: -4px;
    left: 50%;
}

.removeIconColor{
    filter: brightness(0.25);
}


@media (max-width: 978px){
 
    .navIcons{
        width: 40px;
        top: 10px;
    }
    #bigScrollSpyNav{
        width: 50px;
    } 
}