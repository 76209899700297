#footerContainer{
    height:fit-content;
    background-color: #242424!important;
    color: #fff;
}

 /* #footerContainer i{
    color:#7c7c7d
} */

#footerContainer a{
    color: #fff;
}