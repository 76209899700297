#companyOverviewContainer{
    max-width: 1250px;
    padding: 40px 0px;
    margin:auto;
    text-align: left;
}

#Our-esteemed{
    width: 100%;
    height: 250px;
    padding: 100px 0 0 6%; 
    color: #fff;
    text-align: left;
    background-image: url("./AboutUsBanner.jpg");
    background-size: cover
}
#Our-esteemed h1{
    text-align: center;
}

#companyOverviewTabContainer{
    margin: 0px 30px;

}
#companyOverviewTabContainer p{
    padding: 0px 15px;
   font-size: 19px;
   letter-spacing: 0px;
   line-height: 24px;
   text-transform: none;
   font-weight: 500;
   color: #6d7783;
}

#companyOverviewTabContainer .fa-arrow-circle-right{
    margin-right:10px
}

.companyOverviewTabContainerNavLink{
    font-size: 1.2em;
}

#companyOverviewTabContainer .nav-tabs .nav-link{
    cursor: pointer;
}

.boldHeader{
font-weight: bold;
text-decoration: underline;
}

.nav.nav-tabs{
border-bottom: none;
    place-content: center!important;
}
.nav .nav-item{
    background-color: #f3f3f3 !important;
    color:#7b7c7c !important;
    border-radius: 20px 20px 0  0;
    border: 1PX solid #e8e8e8 !important;
}

.tab-content .tab-pane{
    background-color: #f3f3f3 !important;
    border-radius: 20px!important;
    padding: 40px;

}

.fa.fa-arrow-circle-right{
    color: #d9d9d9;
}

#NavLinkOurCompany,
#NavLinkOurServices,
#NavLinkOurTeam{
    color:#7b7c7c !important;
}