#MainNavContainer{
    background-color: #f8f9fa!important;
    }
    
    #NavContainerCentered{
    max-width: 1250px;
    margin: auto;
    }

    .navText{
        /* padding: 0px 15px; */
       font-size: 18px;
       letter-spacing: 0px;
       line-height: 24px;
       text-transform: none;
       font-weight: bold;
       color: #6d7783;
       /* font-family: Roboto; */
   }
   