#contactUsButton{
margin: 40px 70px 0px 70px;
z-index: 1000;
}

#contactUsButton h5{
margin:0
}
#contactUsButton .btn-primary{
    /* height: 60px; */
    width: 100%;
    /* font-size: 3px; */
    font-weight: bold;
}

.AlertRed{
border-color: red !important;
color:red!important
}

.AlertRed:focus, input[custom-file-input]:focus, .uneditable-input:focus {
    border-color: custom-orange;
    box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px custom-orange;
    outline: 0 none;
}

.AlertRed:focus {
    border-color: #ff4444 !important; 
    box-shadow: 0 0 0 0.2rem rgba(255,68,68,.25)!important;
}

.alertModal{
    position: absolute !important;
    width: calc(100% - 2em) !important;
    top: 0 !important;
    animation-name: alertMessagg;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

@keyframes alertMessagg {
    0% { opacity: 0; }  
    100% { opacity: 100; }
  }