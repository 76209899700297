#CtsPrivacyPolicyHISServicesModalMainContainer {
    max-width: 1250px;
    margin: auto;
    text-align: left;
    position: relative;
    font-size: 20px;
}

#CtsPrivacyPolicyHISServicesModalMainContainer div {
    margin-top: 10px;
    /* white-space: nowrap; text-overflow: ellipsis;  overflow: hidden */
}

#CtsPrivacyPolicyHISServicesModalMainContainer .CtsPrivacyPolicyHISServicesModalLine {
    margin-left: 20px;
}

#CtsPrivacyPolicyHISServicesModalMainContainer .CtsPrivacyPolicyHISServicesModalDot {
    float: left;
    margin-top:0;
    padding-right:10px;
}

#CtsPrivacyPolicyHISServicesModalMainContainer div h1,
#CtsPrivacyPolicyHISServicesModalMainContainer div h2,
#CtsPrivacyPolicyHISServicesModalMainContainer div h3,
#CtsPrivacyPolicyHISServicesModalMainContainer div h4,
#CtsPrivacyPolicyHISServicesModalMainContainer div h5 {
    font-weight: bold;
}

#CtsPrivacyPolicyHISServicesModalMainContainer div h1 {
    margin: 40px auto 20px auto;
}

#CtsPrivacyPolicyHISServicesModalMainContainer div h2 {
    margin: 10px auto;
}

#CtsPrivacyPolicyHISServicesModalMainContainer div h3 {
    margin: 10px auto;
}

#CtsPrivacyPolicyHISServicesModalMainContainer div h4 {
    margin: 10px auto;
}

#CtsPrivacyPolicyHISServicesModalMainContainer div h5 {
    margin: 10px auto;
}

.mb-7 {
    margin-bottom: 70px !important;
}

.rubytext {
    padding-left: 10px;
    font-family: ruby-text;
    
}