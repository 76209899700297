#CtsPrivacyPolicyModalMainContainer {
    max-width: 1250px;
    margin: auto;
    text-align: left;
    position: relative;
    font-size: 20px;
}

#CtsPrivacyPolicyModalMainContainer div {
    margin-top: 10px;
    /* white-space: nowrap; text-overflow: ellipsis;  overflow: hidden */
}

#CtsPrivacyPolicyModalMainContainer .CtsPrivacyPolicyModalLine {
    margin-left: 20px;
}

#CtsPrivacyPolicyModalMainContainer .CtsPrivacyPolicyModalDot {
    float: left;
    margin-top:0;
    padding-right:10px;
}

#CtsPrivacyPolicyModalMainContainer div h1,
#CtsPrivacyPolicyModalMainContainer div h2,
#CtsPrivacyPolicyModalMainContainer div h3,
#CtsPrivacyPolicyModalMainContainer div h4,
#CtsPrivacyPolicyModalMainContainer div h5 {
    font-weight: bold;
}

#CtsPrivacyPolicyModalMainContainer div h1 {
    margin: 40px auto 20px auto;
}

#CtsPrivacyPolicyModalMainContainer div h2 {
    margin: 10px auto;
}

#CtsPrivacyPolicyModalMainContainer div h3 {
    margin: 10px auto;
}

#CtsPrivacyPolicyModalMainContainer div h4 {
    margin: 10px auto;
}

#CtsPrivacyPolicyModalMainContainer div h5 {
    margin: 10px auto;
}

.mb-7 {
    margin-bottom: 70px !important;
}

.rubytext {
    padding-left: 10px;
    font-family: ruby-text;
    
}