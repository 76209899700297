#ContactUsModalMainContainer{
        max-width: 1250px;
        padding: 20px 0px;
        margin:auto;
        text-align: left;
}

#ContactUsModalMainContainer .contactBox{
        padding: 0 10% !important;
}

#ContactUsModalMainContainer p{
        color: #6d7783;
      font-size: 19px;
      line-height: 24px;
      letter-spacing: 0px;
      font-weight: 400;  
    }  

