#HomeMainContainer {
    height: 100%;
    overflow: auto;
}

#homeSectionOne {
    position: relative;
}

#homeSectioIntelXeon,
#homeSectionMajorImg {
    color: #000;
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    max-width: 900px;
    padding: 20px 0px;
    margin: auto;
    color: #fff;
}

#homeSectionMajorImg .col-4 {
    cursor: pointer;
}

#homeSectioIntelXeon {
    max-width: 100%;
    top: calc(10% + 150px);
}

#homeSectioIntelXeonUnder {}

@media (max-width: 991px) {
    #homeSectioIntelXeon {
        display: none;
    }

    #homeSectioIntelXeonUnder {
        display: block;
    }
}

@media (min-width: 992px) {
    #homeSectioIntelXeon {
        display: block;
    }

    #homeSectioIntelXeonUnder {
        display: none;
    }
}

@media (max-width: 1500px) {
    #homeSectioIntelXeon {
        top: calc(10% + 100px);
    }
}

@media (max-width: 1200px) {
    #homeSectioIntelXeon {
        top: calc(10% + 400px);
    }
}

#homeSectionMajor .homeCtsIcons {
    margin-bottom: 10px;
}

#homeSectionMajor .col-sm-12 {
    margin-bottom: 30px;
}

#homeSectionMajorImg .homeCtsIcons {
    margin-bottom: 0;
    filter: brightness(0) invert(1);
}

#homeSectionMajor {
    display: none;
    padding-bottom: 0;
}

#homeSectionMajorImg a:hover,
#homeSectionMajor a:hover {
    cursor: pointer;
}

#homeSectionMajor,
#homeSectionTow {
    max-width: 1250px;
    padding: 20px 0px;
    margin: auto;
}

#homeSectionMajor .section-tow-header,
#homeSectionTow .section-tow-header {
    margin-bottom: 12px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: normal;
    text-transform: uppercase;
    font-weight: 900;
    color: #293340;
}

#homeSectionThree {
    height: 140px;
    color: #fff;
    background-color: #3A78FF;
}

#homeSectionThree h3 {
    position: relative;
    top: 60px;
    margin: auto;
}

.homeCtsIcons {
    height: 56px;
    width: 56px;
    align-self: center;
    margin: auto;
    margin-bottom: 30px;
}

#homeSectionFive {
    overflow: hidden;
}

#homeSectionFive img {
    min-height: 250px;
}

#homeSectionFive .counterImg {
    position: absolute;
    color: #fff;
    width: 100%;
    height: 100%;
    z-index: 1;
    font-size: 5em;
    font-weight: bold;
    padding-top: 16%;
}

#HomeMainContainer p {
    padding: 0px 15px;
    font-size: 17px;
    letter-spacing: 0px;
    line-height: 24px;
    text-transform: none;
    font-weight: 500;
    color: #6d7783;
}

#homeSectionFour .col-sm-12.col-lg-6 {
    max-height: 500px;
    overflow: hidden;
}

#homeSectionFourText {
    margin: 5% auto;
    max-width: 900px;
}

#homeSectionFourText h2 {
    padding: 0 15px;
    text-decoration: underline #3a78ff;
}

#homeSectionFourText .btn-primary {
    margin: 0 15px;
}

@media (max-width: 978px) {
    #homeSectionFive .counterImg {
        padding-top: 10%;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 800px !important
    }
}

@media (max-width: 700px) {
    #homeSectionMajorImg {
        display: none;
    }

    #homeSectionMajor {
        display: block;
    }
}

#homeSectionMajorImg {
    color: #fff !important;
}

#homeSectionMajor a {
    color: #293340;
}


#rotateBanner {
    position: relative;
    animation-name: rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    -webkit-mation-name: rotate;
    -webkit-mation-duration: 5s;
    -webkit-mation-iteration-count: infinite;
    -webkit-mation-timing-function: linear;

    -moz-animation-name: rotate;
    -moz-animation-duration: 5s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
}


@keyframes rotate {
    0% {
        left: -1218px;
        top: 0px;
    }

    100% {
        left: 0;
        top: 0px;
    }
}


@-webkit-keyframes rotate {
    0% {
        left: -1218px;
        top: 0px;
    }

    100% {
        left: 0;
        top: 0px;
    }
}

@-moz-keyframes rotate {
    0% {
        left: -1218px;
        top: 0px;
    }

    100% {
        left: 0;
        top: 0px;
    }
}

#HomeAboutUsImg{
    max-height: 500px;
    object-fit: cover;
}